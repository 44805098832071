<template>
  <div>
    <div class="grid grid-cols-8 gap-6 pt-3">
      <div class="col-span-8 md:col-span-4">
        <h1>Checkout</h1>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form
            class="grid grid-cols-2 gap-6"
            @submit.prevent="handleSubmit(checkOut)"
          >
            <!-- email -->
            <ValidationProvider
              class="col-span-2"
              tag="div"
              rules="required|email"
              v-slot="{ errors }"
            >
              <span>E-mail</span>
              <input
                class="
                  w-full
                  px-3
                  py-2
                  border border-black border-box
                  leading-none
                  outline-none
                "
                v-model="orderDetails.mollie.email"
                type="email"
                name="Email"
              />
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
            <!-- given name -->
            <ValidationProvider
              class="col-span-1"
              tag="div"
              rules="required"
              v-slot="{ errors }"
            >
              <span>Name</span>
              <input
                class="
                  w-full
                  px-3
                  py-2
                  border border-black border-box
                  leading-none
                  outline-none
                "
                v-model="orderDetails.mollie.givenName"
                type="text"
                name="Name"
              />
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
            <!-- family name -->
            <ValidationProvider
              class="col-span-1"
              tag="div"
              rules="required"
              v-slot="{ errors }"
            >
              <span>Surname</span>
              <input
                class="
                  w-full
                  px-3
                  py-2
                  border border-black border-box
                  leading-none
                  outline-none
                "
                v-model="orderDetails.mollie.familyName"
                type="text"
                name="Surname"
              />
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
            <!-- street + number -->
            <ValidationProvider
              class="col-span-2"
              tag="div"
              rules="required"
              v-slot="{ errors }"
            >
              <span>Street + Number</span>
              <input
                class="
                  w-full
                  px-3
                  py-2
                  border border-black border-box
                  leading-none
                  outline-none
                "
                v-model="orderDetails.mollie.streetAndNumber"
                type="text"
                name="Street + Number"
              />
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
            <!-- street + number -->
            <ValidationProvider
              class="col-span-1"
              tag="div"
              rules="required"
              v-slot="{ errors }"
            >
              <span>Postal code</span>
              <input
                class="
                  w-full
                  px-3
                  py-2
                  border border-black border-box
                  leading-none
                  outline-none
                "
                v-model="orderDetails.mollie.postalCode"
                type="text"
                name="Postal code"
              />
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
            <!-- city -->
            <ValidationProvider
              class="col-span-1"
              tag="div"
              rules="required"
              v-slot="{ errors }"
            >
              <span>City</span>
              <input
                class="
                  w-full
                  px-3
                  py-2
                  border border-black border-box
                  leading-none
                  outline-none
                "
                v-model="orderDetails.mollie.city"
                type="text"
                name="City"
              />
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
            <!-- country -->
            <ValidationProvider
              class="col-span-1"
              tag="div"
              rules="required|excluded:1"
              v-slot="{ errors }"
            >
              <span>Country</span>
              <select
                class="
                  w-full
                  px-3
                  py-2
                  border border-black border-box
                  leading-none
                  outline-none
                "
                name="Country"
                v-model="orderDetails.mollie.country"
                @change="countrySelected(orderDetails.mollie.country)"
              >
                <option disabled selected value="">Select</option>
                <option
                  v-for="(country, i) in inputCountries"
                  :key="`country-${i}`"
                  :value="country.value"
                >
                  {{ country.label }}
                </option>
              </select>
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
            <!-- shipping method -->
            <div v-show="availableShipping.length > 0" class="col-span-2">
              <span>Shipping method</span>
              <ValidationProvider
                class="grid grid-cols-1 gap-3"
                tag="div"
                v-slot="{ errors }"
                rules="required"
              >
                <label
                  class="
                    flex
                    justify-between
                    p-3
                    border border-black
                    cursor-pointer
                  "
                  v-for="(shipping, i) in availableShipping"
                  :key="`shipping=${i}`"
                >
                  <div class="flex space-x-3">
                    <input
                      class="self-center"
                      type="radio"
                      :id="`shipping-${i}`"
                      name="Shipping method"
                      :value="shipping.shippingId"
                      v-model="orderDetails.shippingId"
                      @change="orderDetails.countryId = shipping.countryId"
                    />
                    <div>
                      <b v-if="shipping.trackNTrace">
                        {{ shipping.shippingType | capitalize }} with Tracking
                      </b>
                      <b v-else>
                        {{ shipping.shippingType | capitalize }} without
                        Tracking </b
                      ><br />
                      {{ countryName }}
                    </div>
                  </div>
                  <div>
                    {{ shipping.unitPrice | currency }}
                  </div>
                </label>
                <span v-if="errors.length > 0">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <!-- payment method -->
            <div class="col-span-2" v-show="paymentMethods.length > 0">
              <span>Payment method</span>
              <ValidationProvider
                class="grid grid-cols-2 xl:grid-cols-3 gap-3"
                tag="div"
                v-slot="{ errors }"
                rules="required"
              >
                <div
                  class="col-span-1 p-3 border border-black"
                  v-for="(method, i) in paymentMethods"
                  :key="`payment-${i}`"
                >
                  <label class="flex justify-between cursor-pointer">
                    <div class="flex space-x-3">
                      <input
                        class="self-center"
                        type="radio"
                        name="Payment method"
                        :id="`payment-${i}`"
                        :value="method.id"
                        v-model="orderDetails.mollie.method"
                      />
                      <div>{{ method.description }}</div>
                    </div>
                    <div>
                      <img :src="method.image.svg" />
                    </div>
                  </label>
                </div>
                <span>{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-span-2">
              <button
                class="bg-black text-white rounded-full outline-none px-2"
                type="submit"
              >
                Proceed to payment
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>

      <div class="col-span-8 md:col-span-4">
        <div class="sticky top-0">
          <h1 class="md:pt-16 md:-mt-14">Summary</h1>
          <div>
            <div>Sub total: {{ subTotal | currency }}</div>
            <div>Shipping costs: {{ shippingCosts | currency }}</div>
            <div>Total: {{ totals | currency }}</div>
          </div>
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import axios from "axios";
import { extend } from "vee-validate";
import { email, required, excluded } from "vee-validate/dist/rules";

extend("excluded", excluded);
extend("email", email);
extend("required", {
  ...required,
  message: "{_field_} is required",
});

export default {
  name: "Checkout",
  metaInfo: {
    title: "Checkout",
  },
  data() {
    return {
      api: process.env.VUE_APP_API_URL,
      orderDetails: {
        mollie: {
          country: "",
          method: "ideal",
        },
        countryId: null,
        shippingId: null,
      },
      shippingCosts: 0,
    };
  },
  created() {
    this.$store.dispatch("fetchPaymentMethods");

    if (this.countries.length === 0) this.$store.dispatch("fetchCountries");
  },
  watch: {
    "orderDetails.shippingId"() {
      // get ID
      this.orderDetails.countryId = this.availableShipping
        .filter((item) => {
          return item.shippingId === this.orderDetails.shippingId;
        })
        .map((item) => {
          return item.countryId;
        })[0];
      // get price
      this.shippingCosts = this.availableShipping
        .filter((item) => {
          return item.shippingId === this.orderDetails.shippingId;
        })
        .map((item) => {
          return String(item.unitPrice);
        })[0];
    },
  },
  methods: {
    checkOut() {
      let order = {
        mollie: {
          billingAddress: {
            email: this.orderDetails.mollie.email,
            givenName: this.orderDetails.mollie.givenName,
            familyName: this.orderDetails.mollie.familyName,
            streetAndNumber: this.orderDetails.mollie.streetAndNumber,
            postalCode: this.orderDetails.mollie.postalCode,
            city: this.orderDetails.mollie.city,
            country: this.orderDetails.mollie.country,
          },
          shippingAddress: {
            email: this.orderDetails.mollie.email,
            givenName: this.orderDetails.mollie.givenName,
            familyName: this.orderDetails.mollie.familyName,
            streetAndNumber: this.orderDetails.mollie.streetAndNumber,
            postalCode: this.orderDetails.mollie.postalCode,
            city: this.orderDetails.mollie.city,
            country: this.orderDetails.mollie.country,
          },
          method: this.orderDetails.mollie.method,
          lines: this.cartProducts,
        },
        countryId: this.orderDetails.countryId,
        shippingId: this.orderDetails.shippingId,
      };

      this.$nextTick(() => {
        // create order
        axios.post(`${this.api}/order`, order).then((response) => {
          window.location = response.data._links.checkout.href;
        });
      });
    },
    countrySelected(country) {
      if (country !== null) this.$store.dispatch("selectCountry", country);

      this.$nextTick(() => {
        this.orderDetails.shippingId = this.availableShipping[0].shippingId;
      });
    },
  },
  computed: {
    countryName() {
      return this.inputCountries
        .filter((country) => {
          return country.value === this.orderDetails.mollie.country;
        })
        .map((country) => {
          return country.label;
        })[0];
    },
    products() {
      return this.$store.getters.products;
    },
    inputCountries() {
      return this.$store.getters.inputCountries;
    },
    countries() {
      return this.$store.getters.countries;
    },
    availableShipping() {
      return this.$store.getters.availableShipping;
    },
    selectedShipping() {
      return this.$store.getters.shippingCountry;
    },
    cartProducts() {
      return this.$store.getters.cartProducts;
    },
    cartProductsLength() {
      return this.$store.getters.numberOfProductsInCart;
    },
    subTotal() {
      return this.$store.getters.subTotal;
    },
    totals() {
      return (
        parseFloat(this.$store.getters.totals) + parseFloat(this.shippingCosts)
      );
    },
    paymentMethods() {
      return this.$store.getters.paymentMethods;
    },
  },
};
</script>